import Vue from 'vue'
import VueHead from 'vue-head'
import App from './App.vue'
import { BootstrapVue } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'maplibre-gl/dist/maplibre-gl.css'

Vue.config.productionTip = false

Vue.use(BootstrapVue);
Vue.use(VueHead);

new Vue({
  render: h => h(App)
}).$mount('#app')