<template>
  <div id="app">
    <Header/>
    <Disclaimer/>
    <MapPane/>
    <Footnotes/>
    <!--
    <Maintenance/>
    -->
  </div>
</template>

<script>
import Disclaimer from './components/Disclaimer.vue'
import Footnotes from './components/Footnotes.vue'
import Header from './components/Header.vue'
import MapPane from './components/MapPane.vue'
/*
import Maintenance from './components/Maintenance.vue'
*/

export default {
  name: 'App',
  head: {
    title: function () {
      return {
        inner: "Toronto Fire Active Incidents"
      }
    },
    meta: [
      { name: 'description', content: 'Toronto Fire Active Incidents dashboard and analytics by fans.', id: 'desc' }
    ]
  },
  components: {
    Disclaimer,
    Footnotes,
    Header,
    MapPane
    /*
    Maintenance
    */
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
