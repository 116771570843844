<template>
    <div class='mapPane'>
        <div>Last updated: {{lastUpdateTime}}</div>
        <div id='map'></div>
        <div>
            <b-table
                :striped="false"
                :bordered="true"
                :borderless="false"
                :outlined="false"
                :small="false"
                :hover="true"
                :dark="false"
                :fixed="false"
                :foot-clone="false"
                :no-border-collapse="false"
                :fields="fields"
                :items="events"
                head-variant="light"
            ></b-table>
        </div>
    </div>
</template>

<script>
    import AmazonLocation from 'amazon-location-helpers'
    import axios from 'axios'
    import _ from 'lodash'
    import { drawPoints } from 'maplibre-gl-js-amplify'

    const identityPoolId = process.env.VUE_APP_IDENTITY_POOL_ID;
    const mapName = process.env.VUE_APP_MAP_NAME;
    const serviceURL = process.env.VUE_APP_SERVICE_URL;
    const apiKey = process.env.VUE_APP_API_KEY;

    const tableFields = [
        {
            key: "prime_street"
        }, 
        {
            key: "cross_streets"
        }, 
        {
            key: "dispatch_time",
            sortable: true
        }, 
        {
            key: "event_num",
            label: "Incident Number",
            sortable: true
        }, 
        {
            key: "event_type",
            label: "Incident Type",
            sortable: true
        }, 
        {
            key: "alarm_lev",
            label: "Alarm Level",
            sortable: true
        }, 
        {
            key: "beat",
            label: "Area",
            sortable: true
        }, 
        {
            key: "units_disp",
            label: "Dispatched Units"
        }];

    export default {
        name: 'MapPane',
        head () {
            return {
                title: "Toronto Fire Active Incidents",
                meta: [
                    {
                        hid: 'description',
                        name: 'description',
                        content: 'Toronto Fire Active Incidents dashboard and analytics by fans.'
                    }
                ]
            }
        },
        data () { 
            return {
                lastUpdateTime: "",
                events: [],
                fields: tableFields
            }
        },
        mounted: async function () {
            await this.mapCreate();
        },
        methods: {
            mapCreate: async function() {
                const apiResponse = await axios.get(serviceURL, {
                    headers: {
                        "x-api-key": apiKey
                    }
                });
                const data = apiResponse["data"];
                this.lastUpdateTime = data["lastUpdateTime"];
                const events = _.map(data["events"], event => {
                    // Stringify dispatched units.
                    event["units_disp"] = _.join(event["units_disp"], ', ');
                    return _.omit(event, "_id");
                });
                this.events = events

                const locations = _.chain(data["events"])
                    .filter(event => {
                        return !_.isEmpty(event["coordinates"]);
                    })
                    .map(event => {
                        let address = "";
                        const primeStreet = event["prime_street"].trim();
                        const crossStreets = event["cross_streets"].trim();
                        if(_.isEmpty(primeStreet)) {
                            const crossStreetList = crossStreets.split("/");
                            address = `${crossStreetList[0]} & ${crossStreetList[1]}`;
                        } else if (primeStreet.match(/^[A-Za-z]{1}[0-9]{1}[A-Za-z]{1}/)) {
                            address = primeStreet;  // Postal Code.
                        } else {
                            address = `${primeStreet} & ${crossStreets}`;
                        }
                        return {
                            coordinates: event["coordinates"],
                            title: `${event["event_num"]} - ${event["event_type"]}`,
                            address: address
                        };
                    })
                    .value();

                const map = await AmazonLocation.createMap(
                    {
                        identityPoolId
                    },
                    {
                        container: "map",
                        center: [-79.3832, 43.6532], // Toronto, ON, Canada.
                        zoom: 10,
                        style: mapName,
                        hash: true,
                    }
                );
                
                map.on("load", function () {
                    drawPoints("mySourceName", // Arbitrary source name
                        locations,
                        map,
                        {
                            showCluster: false,
                            unclusteredOptions: {
                                showMarkerPopup: true,
                            },
                            clusterOptions: {
                                showCount: true,
                            },
                        }
                    );
                });
            },
        }
    }
</script>

<style scoped>
    /* import @'https://unpkg.com/maplibre-gl@1.14.0/dist/maplibre-gl.css' */
    .mapPane {
        padding: 5rem;
    }

    #map {
        z-index: 0;
        height: 800px;
    }
</style>